/* eslint-disable react/prop-types */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { firebase } from './src/config';
import { FirebaseProvider } from '@churni/fire';
import { GlobalWrapper } from '@churni/common';

import 'lazysizes';

export const wrapRootElement = ({ element }) => {
  // Lazy load firebase core (auth & analytics) and
  return <FirebaseProvider config={firebase}>{element}</FirebaseProvider>;
};

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.createRoot(container, {
      hydrate: true,
      hydrationOptions: { onHydrated: callback }
    }).render(element);
  };
};
